import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  ListingStyle,
} from "./category-nav.module.css"
import CategoryCard from "./category-card"

const CategoryNav = () => {
  const {
    books, merch, pickles, rare, requests, giftCard
  } = useStaticQuery(graphql`
    query collectionsQuery {
      books: shopifyCollection(title: {eq: "Fiction"}) {
        title
        image {
          gatsbyImageData(width: 910)
          originalSrc
          altText
        }
      }
      merch: shopifyCollection(title: {eq: "Merch"}) {
        title
        image {
          gatsbyImageData(width: 910)
          originalSrc
          altText
        }
      }
      pickles: shopifyCollection(title: {eq: "Pickles"}) {
        title
        image {
          gatsbyImageData(width: 910)
          originalSrc
          altText
        }
      }
      rare: shopifyCollection(title: {eq: "Rare"}) {
        title
        image {
          gatsbyImageData(width: 910)
          originalSrc
          altText
        }
      }
      requests: allSanityRequestForm {
        edges {
          node {
            requestsImage {
              asset {
                gatsbyImageData(width: 910)
              }
            }
          }
        }
      }
      giftCard: shopifyProduct(isGiftCard: {eq: true}) {
        images {
          gatsbyImageData(width: 910)
        }
      }
    }
  `)

  return (
    <div className={ListingStyle}>
      <CategoryCard
        slug="/shop/pickles"
        title={pickles.title}
        image={pickles.image}
      />
      <CategoryCard
        slug="/shop/fiction"
        title={books.title}
        image={books.image}
      />
      <CategoryCard
        slug="/shop/rare"
        title={rare.title}
        image={rare.image}
      />
      <CategoryCard
        slug="/shop/merch"
        title={merch.title}
        image={merch.image}
      />
      <CategoryCard
        slug="/requests"
        title={"Requests"}
        image={requests.edges[0].node.requestsImage.asset}
      />
      <CategoryCard
        slug="/gift-card"
        title={"Gift Card"}
        image={giftCard.images[0]}
      />
    </div>
  )
}

export default CategoryNav

// localFile {
//   childImageSharp {
//     gatsbyImageData(width: 910)
//   }
// }
// import { GatsbyImage, getImage } from "gatsby-plugin-image"

// function ProductListing(product) {
//   const image = getImage(product.featuredImage.localFile)

//   return <GatsbyImage image={image} alt={product.featuredImage.altText} />
// }