import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import IndexLanding from "../components/index-landing"
import CategoryNav from "../components/category-nav"
import PressModule from "../components/press-module"

const HomePage = () => {
  return (
    <Layout containerStyle="hasLanding">
      <Seo title={"Home"} />

      <IndexLanding />
      <CategoryNav />
      <PressModule />
    </Layout>
  )
}
export default HomePage