import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { announcement, visible, invisible } from "./announcement.module.css"
import serializers from "../utils/serializers"
const BlockContent = require('@sanity/block-content-to-react')

const Announcement = () => {
  const [toggleVis, setToggleVis] = React.useState(true)
  const { allSanitySiteSettings } = useStaticQuery(graphql`
    query {
      allSanitySiteSettings {
        edges {
          node {
            _rawAnnoucement
          }
        }
      }
    }
  `)
  return (
    <aside className={`${announcement} ${toggleVis ? visible : invisible}`}>
      <BlockContent 
        blocks={allSanitySiteSettings.edges[0].node._rawAnnoucement} 
        serializers={serializers}
      />
      <img onClick={() => setToggleVis(false)} src="/green-x.svg" alt="x" />    
    </aside>
  )
}

export default Announcement