import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Announcement from "./announcement"
import { 
  floating,
  shadow,
  landing,  
  sloganContainer,
  sloganAddress,
  addressArrow,
  mainIllus,
  mixedFontLink,
  sans,
  serif,
  merchLink,
  booksLink,
  desktopArrow,
  mobileArrow,
  hovered,
  notHovered,
 } from "./index-landing.module.css"
import LargeSlogan from "./large-slogan"

const IndexLanding = () => {
  const { allSanitySiteSettings } = useStaticQuery(graphql`
    query {
      allSanitySiteSettings {
        edges {
          node {
            merchImage {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
            booksImage {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
            picklesImage {
              asset {
                gatsbyImageData(placeholder: NONE)
              }
            }
          }
        }
      }
    }
  `)  
  const [splash, setSplash] = React.useState("pickles")

  return (
    <div className={landing}>
      <Announcement />
      <div className={sloganContainer} onMouseEnter={() => setSplash("pickles")}>
        <LargeSlogan />
        <p className={sloganAddress}>47 orchard street<br />new york, ny 10002<img className={addressArrow} src="addressArrow.svg" alt="decorative arrow" /></p>
      </div>

      <Link onMouseEnter={() => setSplash("merch")} className={`${mixedFontLink} ${splash==="merch" ? hovered : notHovered}`} id={merchLink} to="/shop/merch">
        <span className={sans}>Shop</span>
        <span className={serif}>Merch</span>
      </Link>
      {splash==="pickles" &&
        <GatsbyImage
          alt={""}
          image={allSanitySiteSettings.edges[0].node.picklesImage.asset.gatsbyImageData}
          className={floating}
          loading={"eager"}
          style={{ gridArea: "pickle",  width: "100%", justifySelf: "center", alignSelf: "start"}}
          imgStyle={{ objectFit: "contain" }}
          placeholder="NONE"
          layout="constrained"
        />   
      }
      {splash==="merch" &&
        <GatsbyImage
          alt={""}
          image={allSanitySiteSettings.edges[0].node.merchImage.asset.gatsbyImageData}
          className={floating}
          loading={"eager"}
          style={{ gridArea: "pickle",  width: "100%", justifySelf: "center", alignSelf: "start"}}
          imgStyle={{ objectFit: "contain" }}
          placeholder="NONE"
          layout="constrained"
        />   
      }
      
      {splash==="books" &&
        <GatsbyImage
        alt={""}
        image={allSanitySiteSettings.edges[0].node.booksImage.asset.gatsbyImageData}
        className={floating}
        loading={"eager"}
        style={{ gridArea: "pickle",  width: "100%", justifySelf: "center", alignSelf: "start"}}
        imgStyle={{ objectFit: "contain" }}
        placeholder="NONE"
        layout="constrained"
      /> 
    }
      <Link onMouseEnter={() =>setSplash("books")} className={`${mixedFontLink} ${splash==="books" ? hovered : notHovered}`} id={booksLink} to="/shop/fiction">
        <span className={sans}>Shop</span>
        <span className={serif}>Books</span>
      </Link>
    </div>
  )
}
export default IndexLanding