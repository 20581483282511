import * as React from "react"
import { Link } from "gatsby"
import ImageCard from "./image-card"

import {
  productCardStyle,
  productHeadingStyle,
} from "./category-card.module.css"

const CategoryCard = ({ image, title, slug }) => {

  return (
    <Link
      className={productCardStyle}
      to={slug}
      aria-label={`View ${title} product page`}
    >
      <ImageCard image={image} />
   
      <h2 className={productHeadingStyle}>
        {title}
      </h2>        
   </Link>
  )
}

export default CategoryCard

