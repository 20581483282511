// extracted by mini-css-extract-plugin
export var addressArrow = "index-landing-module--addressArrow--jtfZJ";
export var booksLink = "index-landing-module--booksLink--+PfQC";
export var desktopArrow = "index-landing-module--desktopArrow--1-X2x";
export var floating = "index-landing-module--floating--m68U3";
export var hiddenSplash = "index-landing-module--hiddenSplash--5HvA7";
export var hovered = "index-landing-module--hovered--kOxgv";
export var landing = "index-landing-module--landing--82s-U";
export var mainIllus = "index-landing-module--mainIllus--MwJww";
export var merchLink = "index-landing-module--merchLink--Fx9qC";
export var mixedFontLink = "index-landing-module--mixedFontLink--T--ih";
export var mobileArrow = "index-landing-module--mobileArrow--DycnM";
export var notHovered = "index-landing-module--notHovered--Drwtz";
export var sans = "index-landing-module--sans--LqI4O";
export var serif = "index-landing-module--serif--49ycL";
export var shadow = "index-landing-module--shadow--BKsGD";
export var sloganAddress = "index-landing-module--sloganAddress--IXZ8O";
export var sloganContainer = "index-landing-module--sloganContainer--k3O7x";